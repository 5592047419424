<template>
  <div class="wap-recharge">
    <headerBar
      :title="$t('chong-zhi-0')"
      @back="back"
      :right-text="$t('chong-zhi-ji-lu')"
      @detail="toDetail"
    ></headerBar>
    <div class="top-info">
      <img :src="bannerUrl" alt="" />
      <div class="info">
        {{
          $t(
            'qing-ru-shi-tian-xie-shi-ji-zhuan-zhang-jine-fou-ze-ke-neng-dao-zhi-xi-tong-shen-he-wu-fa-tong-guo-ying-xiang-chong-zhi-jin-du'
          )
        }}
      </div>
    </div>

    <div class="bit-list">
      <div
        class="list flex-center-between"
        v-for="(item, i) in bitList"
        :key="i"
        @click="toRecharge(item)"
      >
        <div class="flex-center">
          <img :src="iconObj[item.RechargeNetwork]" alt="" />
          <span>{{ item.RechargeNetwork }}</span>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { getRechargeInfo } from '@/api/user'
import { Field, Button } from 'vant'
export default {
  components: {
    headerBar,
    Field,
    Button
  },
  data() {
    return {
      pageType: '',
      bannerUrl: require('@/assets/imgs/recharge-bg.png'),
      bitList: [],
      iconObj: {
        'USDT-TRC20': require('@/assets/imgs/icon-bit3.png'),
        'USDT-ERC20': require('@/assets/imgs/icon-bit3.png'),
        ETH: require('@/assets/imgs/icon-bit1.png'),
        BTC: require('@/assets/imgs/icon-bit2.png')
      }
    }
  },
  computed: {},
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    init() {
      getRechargeInfo().then((res) => {
        this.bitList = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    toRecharge(data) {
      this.$router.push({
        name:
          this.pageType == 'user' ? 'wapUserRechargeInfo' : 'wapRechargeInfo',
        query: {
          type: data.RechargeNetwork
        }
      })
    },
    toDetail() {
      this.$router.push({
        name: 'wapRechargeLog'
      })
    }
  }
}
</script>